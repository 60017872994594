module.exports = [{
      plugin: require('../plugins/hometown-source-plugin/gatsby-browser.js'),
      options: {"plugins":[],"basicAuth":{},"headers":{"Authorization":"Basic ZmVsaXBlYWRtaW46ZGRhYXJyd3dvb2ZmdHQ0NTk="},"fetchFromFile":false,"demoBuild":false,"fastBuild":false,"site":"dumpster","staticSiteDomain":"https://www.hometowndumpsterrental.com","baseUrl":"https://api.bookable.com","buildmode":"homepage","env":"production","showSkipped":false,"skipCountValidation":"false"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T6WTDVC","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.hometowndumpsterrental.com","stripQueryString":true},
    },{
      plugin: require('../plugins/gatsby-plugin-home-css-entrypoint/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
